

















import Vue from 'vue';
import { ChevronRightIcon } from 'vue-feather-icons';
import { mapMutations } from 'vuex';
import { EnumsActions, EnumsIcons } from '@/store/modules/globals/types';

export default Vue.extend({
  name: 'ConfigurationMain',
  components: {
    ChevronRightIcon,
  },
  data() {
    return {
      configurations: [
        {
          title: 'configurations.inventory-templates',
          path: '/configuration/inventory-templates',
        },
        {
          title: 'configurations.shipping-provider',
          path: '/configuration/shipping-provider',
        },
      ],
    };
  },
  methods: {
    ...mapMutations('globals', ['SET_TITLE', 'SET_ICON', 'SET_ACTION']),
  },
  created() {
    this.SET_TITLE('header.configuration');
    this.SET_ICON(EnumsIcons.processes);
    this.SET_ACTION(EnumsActions.processes);
  },
});
